import app from 'firebase/app';
import 'firebase/database';

const config = {
    apiKey: "AIzaSyCMmaNzfO9tuzwBQfdwxj3P8rc3zMsubSI",
    authDomain: "rio-travels-india.firebaseapp.com",
    databaseURL: "https://rio-travels-india.firebaseio.com",
    projectId: "rio-travels-india",
    storageBucket: "rio-travels-india.appspot.com",
    messagingSenderId: "421761447141",
    appId: "1:421761447141:web:3910737149918575"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    
    this.db = app.database();
  }
  packages = () => this.db.ref('packages')
}


export default Firebase;