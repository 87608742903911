import React from 'react';
import {BrowserRouter,Route, Switch } from 'react-router-dom'
import asyncComponent from "./components/AsyncComponent";
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import whats from "./assets/whatsapp-logo.png";
const AsyncLand = asyncComponent(() => import("./pages/Land"));
const AsyncPackage = asyncComponent(() => import("./pages/Package"));
const AsyncPackageList = asyncComponent(() =>
  import("./components/PackageList/PackageDetail/PackageDetail")
);
const AsyncContact = asyncComponent(() => import("./pages/Contact/Contact"));

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/" component={AsyncLand} />
          <Route exact path="/packages" component={AsyncPackage} />
          <Route path={"/packages/:title"} component={AsyncPackageList} />
          <Route path="/contact" component={AsyncContact} />
        </Switch>
        <Footer />
      </div>
      <div style={{ position: "fixed", bottom: 0, right: 0 }}>
        {/* <span className={"fa fa-whatsapp "}></span> */}
        <a href=" https://wa.me/919910994216" target={"_blank"}>
          <img src={whats} style={{ width: "5rem" }}></img>
        </a>
      </div>
    </BrowserRouter>
  );
}

export default App;
