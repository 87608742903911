import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import logo from '../../assets/RTI.png';
import './Header.css'
import styles from './Header.module.css'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem } from 'reactstrap';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div className='fixed-top'>
        <div className={styles.contactStrip}>
          <span className={"fa fa-phone "+styles.phoneIcon}></span> <a href="tel:+919910994216">9910994216</a> 
          <span className={"fa fa-envelope " + styles.emailIcon}></span> <a href="mailto:riotravelsindia@gmail.com">riotravelsindia@gmail.com</a>
        </div>
        <Navbar color="faded" light expand="md" >
        <div className={styles.logoContainer}>
          <NavLink className="" exact to="/">
            <img className={styles.logo} alt="Logo" src={logo}/>
          </NavLink>
        </div>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
            <NavItem>
                <NavLink exact to="/packages" className="nav-link" onClick={this.toggle}>Packages</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/contact" className="nav-link" onClick={this.toggle}>Contact</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}