import React, { Component } from 'react';
import styles from './Footer.module.css'

class Footer extends Component {
    render() {
        return (
            <div className={styles.wrapper}>
            <div className="container">
            <div className="row">
                <div className="col-sm-6">
                    <div className={styles.header}>
                        Links
                    </div>
                    <div className={styles.links}>
                        <div>
                            <a href="/packages">Packages</a>
                        </div>
                        <div>
                            <a href="/packages">Contact Us</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className={styles.header}>
                        Social Media
                    </div>
                    <div>
                        <span className={styles.iconWrapper}>
                            <a href="https://www.facebook.com/riotravelsindia" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-square"></i></a>
                        </span>
                        <span className={styles.iconWrapper}>
                        <a href="https://twitter.com/riotravelsindi1" rel="noopener noreferrer" target="_blank"><i className="fab fa-twitter-square"></i></a>
                        </span>
                    </div>
                </div>
            </div>
                <i className="far fa-copyright" /> ADev 2019
            </div>
            </div>
        );
    }
}

export default Footer;